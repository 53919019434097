import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button, Tab } from '@carbon/react';
import Layout from './Layout';
import './ArtistPage.css'; 
import { CheckmarkFilled } from '@carbon/icons-react';
import { Tabs, TabList, TabPanels, TabPanel, Breadcrumb, BreadcrumbItem } from '@carbon/react';
import SongFeed from './SongFeed';
import { useOutletContext } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { fetchAuthSession } from '@aws-amplify/auth';
import awsconfig from './aws-exports';

function ArtistPage() {
  const [artist, setArtist] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  const { artistId } = useParams(); // Get the artistId from the URL
  const [cookies] = useCookies(['csrftoken']);
  const [artistProfilePictureUrl, setArtistProfilePictureUrl] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const { setCurrentSong } = useOutletContext();
  const [bearerToken, setBearerToken] = useState(null);

  const fetchBearerToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();
      setBearerToken(token);
    } catch (error) {
      console.error("Failed to fetch token", error);
    }
  };

  useEffect(() => {
    fetchBearerToken();
  }, []);

  useEffect(() => {
    const fetchArtist = async () => {
      if (!bearerToken) return;
      const response = await fetch(`/Apis/artists/${artistId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const data = await response.json();
      setArtist(data);
      setArtistProfilePictureUrl(data.profile_image);
      setCoverImageUrl(data.artist_cover_image_url);
    };

    const fetchSubscriptionOptions = async () => {
      if (!bearerToken) return;
      const response = await fetch(`/Apis/subscriptionOptions/${artistId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const data = await response.json();
      console.log(data);
      setSubscriptionOptions(data); // Assuming the response has an options array
    };

    const fetchTrackDetails = async () => {
      if (!bearerToken) return;
      const response = await fetch(`/Apis/get_tracks_for_artist/${artistId}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const data = await response.json();
      setTracks(data);
    };

    if (bearerToken) {
      console.log('has bearer');
      fetchArtist();
      fetchSubscriptionOptions();
      fetchTrackDetails();
    }
    console.log("jkasdfkjlasdkflaskjdf");
  }, [artistId, bearerToken]);

  const handleSubscribeClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSubscriptionSelect = (subscriptionId) => {
    setSelectedSubscriptionId(subscriptionId);
  };

  const handleSubscribe = async () => {
    try {
      const response = await fetch('/Apis/subscribe', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
          'Authorization': `Bearer ${bearerToken}`
        },
        body: JSON.stringify({ 'tier_id': selectedSubscriptionId })
      });
      if (!response.ok) throw new Error('Failed to subscribe');
      alert('Subscription successful!');
      setShowModal(false);
    } catch (error) {
      console.error('Subscription error:', error);
      alert('Subscription failed.');
    }
  };

  if (!artist) {
    return <div>Loading...</div>;
  }

  return (
    <div className="artistPage">
      <div className="artist-page-header" style={{backgroundImage: `url(${coverImageUrl})`, backgroundSize: 'cover'}}>
        <Breadcrumb className="breadcrumb" noTrailingSlash={true}> 
          <BreadcrumbItem>
            Profiles
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            {artist.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="artistHeader">
          <h2 className="artistName">{artist.name}</h2>
          <CheckmarkFilled className="check" color="#6B57F0" />
        </div>
        <text className="numberOfListeners"> 300k monthly listeners </text>
      </div>

      <div className="artistImageAndSubscribe">
        <img src={artistProfilePictureUrl} className="profilePicture" alt="Artist" />
        <div className="subscribeAndNumberOfListeners">
          <Button className="subscribe" kind="primary" onClick={handleSubscribeClick} size="lg">Subscribe</Button>
        </div>
      </div>

      <a className="bio">
      {artist.bio} 
      </a>

      <div className="tabsDiv">
        <Tabs className="tabs">
          <TabList className="tabList" aria-label="List of tabs" contained>
            <Tab className="customtab">Music</Tab>
            <Tab>Merch</Tab>
            <Tab>Tour</Tab>
          </TabList>
          <TabPanels className="tabPanels">
            <TabPanel className="songfeed"><SongFeed setCurrentSong={setCurrentSong} tracks={tracks}/></TabPanel>
            <TabPanel>Tab Panel 2</TabPanel>
            <TabPanel>Tab Panel 3</TabPanel>
          </TabPanels>
        </Tabs>
      </div>

      <Modal
        open={showModal}
        modalHeading="Choose Your Subscription"
        primaryButtonText="Subscribe"
        secondaryButtonText="Cancel"
        onRequestClose={handleModalClose}
        onRequestSubmit={handleSubscribe}
      >
        {subscriptionOptions.map(option => (
          <Button
            key={option.id}
            onClick={() => handleSubscriptionSelect(option.id)}
            className={selectedSubscriptionId === option.id ? 'selected' : ''}
          >
            {option.tier_name} - ${option.price}
          </Button>
        ))}
      </Modal>
    </div>
  );
}

export default ArtistPage;

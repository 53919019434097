import React,  { useRef, useEffect, useState } from 'react';
//import 'shaka-player/dist/controls.css';
import './Main.css'; 
import rectangle from './images/rectangle.svg'
import shaka from 'shaka-player';
import {Search, SideNav, SideNavItems, SideNavLink, SideNavDivider, SideNavMenu, SideNavMenuItem, Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction  } from '@carbon/react';
import { Home, Compass, Radio, Music, User, CenterCircle, Playlist, Upload, UserAvatar, MediaLibrary, UserProfile } from '@carbon/icons-react'; // Import the home icon
import logo from './images/logo.svg'
import SongFeed from './SongFeed';
import { useOutletContext } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';

function Main() {

  const { user } = useAuthenticator((context) => [context.user]);
  const dynamicItems = [
    { id: 1, label: 'Dynamic Option 1', href: '/DynamicOption1' },
    { id: 2, label: 'Dynamic Option 2', href: '/DynamicOption2' },
    // Add more dynamic items as needed
  ];

  const audioRef = useRef(null);
  const [showSongFeed, setShowSongFeed] = useState(true);
  const [showLikedSongs, setLikedSongs] = useState(false);
  const {setCurrentSong} = useOutletContext();
  const [tracks, setTracks] = useState([]);
  const [query, setQuery] = useState('');

  // useEffect(() => {
  //   console.log("REMOUNTING!!!!");
  //   const player = new shaka.Player(audioRef.current);
  //   // Replace with the URL of your MPEG-DASH or HLS manifest
  //   const videoSource = 'https://livesim.dashif.org/dash/vod/testpic_2s/audio.mpd';
  //   player.load(videoSource);
  //   const video = document.getElementById('shaka-player-container');
  //   console.log(video);
  //   //const ui = video['ui'];
  //   const config = {
  //     addSeekBar: false
  //   };
  //   //ui.configure(config);
  //   return () => {
  //     player.destroy();
  //   };
  // }, []);


  // const config = {
  //   controlPanelElements: ['play_pause_on_play_button_pressed'],
  // };

  //const videoElement = document.getElementById('myVideoElement');


  const fetchSongs = (q) => {

    if (!q) {
      fetchTrackDetails();
    }

    fetch(`/Apis/search_songs/?q=${encodeURIComponent(q)}`)
      .then((response) => response.json())
      .then((data) => {
        setTracks(data); // Assuming the API returns an array of songs
      })
      .catch((error) => console.error("Failed to fetch songs", error));
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    fetchSongs(e.target.value);
  };

  const toggleSongFeed = () => {
    setShowSongFeed(true);
    setLikedSongs(false);
  };

  const toggledLikedSongs = () => {
    setLikedSongs(true);
    setShowSongFeed(false);
  };

  const fetchTrackDetails = async () => {
    const session = await fetchAuthSession();   // Fetch the authentication session
    const token = session.tokens.accessToken.toString();
    const response = await fetch(`/Apis/recent_twenty_tracks`, {
      headers: {
          'Authorization': `Bearer ${token}` // Add the Bearer token
      },
      credentials: 'include'});
    const data = await response.json();
    setTracks(data);
  };

  useEffect(() => {
    console.log(window.location.hostname);
    fetchTrackDetails();
  }, []);

  return (
    <div className="container">
        <div className="top_bar_div">
      <Search 
        className="search_bar"
        onChange={handleSearchChange}
        placeholder="Search for artists, albums, songs, podcasts..."
        label="Search for artists, albums, songs, podcasts..."
        size="lg"
      />
    </div>
      <SongFeed setCurrentSong={setCurrentSong} tracks={tracks} />
    </div>
  );
}

export default Main;


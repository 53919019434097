import React, { useState } from 'react';
import './SongCard.css'; // Import your CSS file for styling
import logo from './images/logo.svg'
import albumart from './images/albumart.svg'
import vector from './images/overflowmenu.svg'
import add from './images/vector.svg'
import checkmark from './images/checkmark.svg'
import { CheckmarkFilled, FavoriteFilled, Add, Favorite, OverflowMenuHorizontal, PlayFilledAlt, PauseFilled} from '@carbon/icons-react';
import { useCookies } from 'react-cookie';
import {Link } from 'react-router-dom';


import { Button, OverflowMenu, OverflowMenuItem, Tag } from '@carbon/react';


// Function to toggle the button's content


function SongCard({ key, rank, setCurrentSong, song }) {

  const [isSongLiked, setSongLiked] = useState(false);
  const track_url = "track_detail/" + song.track_id;
  const [cookies] = useCookies(['csrftoken']);
  const [isHovered, setIsHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const playSong = async () => {
    setCurrentSong(song);
    console.log("set current song");
    console.log(song);
  }

  const addToQueue = async () => {
    try {
      // Make the HTTP request using fetch or axios
      const response = await fetch('/Apis/add_song_to_queue', {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
          },
          body: JSON.stringify({"song_identifier": song.track_id}),

      });

      // Handle the response (e.g., show success message, redirect, etc.)
      if (response.ok) {
        console.log('Song added to queue successfully');
      } else {
        console.error('add to queue failed');
      }
    } catch (error) {
        console.error('Error adding to queue:', error);
    }
  };

  const likeSong = async () => {
    setSongLiked(!isSongLiked);

    try {
      // Make the HTTP request using fetch or axios
      const response = await fetch('/Apis/like_song', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
          },
          body: JSON.stringify({"song_title": "abc"}),
      });

      // Handle the response (e.g., show success message, redirect, etc.)
      if (response.ok) {
        console.log('Song liked successfully');
        navigate("/main");
      } else {
        console.error('like failed');
      }
    } catch (error) {
        console.error('Error during song liking:', error);
    }
  };

  return (
    <div className="song-card" onDoubleClick={playSong} onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}>
        <a className="rank" onClick={playSong}>{isHovered ? (isPlaying ? <PauseFilled size={16}/> : <PlayFilledAlt size={16} />) : rank}</a>
        <img className="art" src={song.song_image_url}/>
        <div className="songAndArtist">
          <Link class="song-title"  to={track_url}>{song.name}</Link>
          <Link class="artist-name" to={"artist/".concat(song.artist_id)}> {song.artist_name}</Link>
        </div>

        <div className="album-title" >
        <Link className="album-title-link">{song.release_name}</Link>
        </div>
        <Tag className="tag" size="Small" Color="Cool gray" State="Enabled">         
          {'Experimental'}
        </Tag>
         <Tag className="tag2" size="Small" Color="Cool gray" State="Enabled">         
          {'Alternative'}
        </Tag>
        <button className="addButton" onClick={likeSong}>
          { isSongLiked ? (       <FavoriteFilled style={{ color: '#8F7EFB' }} />) : (<Favorite className="like"/>) }
        </button>
        <div className="overflow-menu">
          <OverflowMenu className="misc" flipped={true} renderIcon={OverflowMenuHorizontal}>
            <OverflowMenuItem itemText="Add to Playlist" />
            <OverflowMenuItem itemText="Save Song"/>
            <OverflowMenuItem hasDivider itemText="View Artist" href="artist/1"/>
            <OverflowMenuItem itemText="View Album"/>
            <OverflowMenuItem itemText="Add to queue" onClick={addToQueue}/>
          </OverflowMenu>
        </div>
    </div>
  );
}

export default SongCard;
import { ButtonSet, Grid, Column, FormGroup, Stack, Button, TextInput, Search } from '@carbon/react';
import logo from './images/logo.svg'
import { useNavigate } from "react-router-dom";
import SongCard from "./SongCard.js";
import "./SongFeed.css";
import SongCardTableHeader from "./SongCardTableHeader.js";
import React, { useState, useEffect } from 'react';


function SongFeed({setCurrentSong, tracks}) {

  const [trackId, setTrackId] = useState(1);

  //const [tracks, setTracks] = useState([]);


  if (!tracks) {
    return <div></div>;
  }




  return (
  <body className="song-feed">
    <div className="song-cards">
       {tracks.map((track,index) => (
        <SongCard
          key={track.track_id}
          rank={index+1}
          setCurrentSong={setCurrentSong}
          song={track}
        />
      ))}

    </div>
    <footer className="song-feed-footer-container"> 
      ©2024 Kaze. All Rights Reserved.
      <div className="footer-links">
        <a className="privacy-policy" href="">Privacy Policy</a>
        <a className="terms-of-service" href="/Register">Terms of Service</a>
        <a className="contact-us" href="/Register">Contact us</a>
      </div>
    </footer>
  </body>

  );
}

export default SongFeed;

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import Layout from './Layout';
import artistImage from './images/dannybrown.png'
import { CheckmarkFilled } from '@carbon/icons-react';

import ArtistEditPage from './ArtistEditPage';

import { Tabs, TabList, Tab, TabPanels, TabPanel, Button, Breadcrumb, BreadcrumbItem} from '@carbon/react';
import SongFeed from './SongFeed';
import AddSubscriptionTierForm from './AddSubscriptionTierForm'
import './ArtistManagementPage.css'; 

function ArtistManagementPage() {
  const [artist, setArtist] = useState(null);
  const { artistId } = useParams(); // Get the artistId from the URL
  useEffect(() => {
    // Function to fetch artist data from the backend
    const fetchArtist = async () => {
      const response = await fetch(`/Apis/artists/${artistId}`);

      const data = await response.json();
      setArtist(data);
    };

    fetchArtist();
  }, [artistId]);

  if (!artist) {
    return <div>Loading...</div>;
  }


  return (
    <div className="management">
      <Breadcrumb className="breadcrumb">
        <Link to="/main/MyArtists" style={{ textDecoration: 'none', marginRight: '8px' }}>
          <BreadcrumbItem>
            Artist Profiles
          </BreadcrumbItem>
        </Link>
        <Link to= {"/main/artist/".concat(artist.id)} style={{ textDecoration: 'none', marginRight: '8px'  }}>
          <BreadcrumbItem>
           {" ".concat(artist.name)}
          </BreadcrumbItem>
        </Link>

        <BreadcrumbItem href={`/main/EditArtist/${artist.id}`} isCurrentPage>
          Edit
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="artistHeader">
        <h2 className ="artistName">{artist.name}</h2>
      </div>

      <Tabs className="tabs">
          <TabList className="tabList" aria-label="List of tabs">
            <Tab className="customtab">Edit Profile</Tab>
            <Tab>Subscription Tiers</Tab>
            <Tab>Tour</Tab>
          </TabList>
        <TabPanels>
          <TabPanel className="songfeed"><ArtistEditPage/></TabPanel>
          <TabPanel><AddSubscriptionTierForm/></TabPanel>
          <TabPanel>Tab Panel 3</TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default ArtistManagementPage;
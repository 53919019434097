import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from './Layout';
import artistImage from './images/dannybrown.png'
import { CheckmarkFilled } from '@carbon/icons-react';



import { Tabs, TabList, Tab, TabPanels, TabPanel, Button } from '@carbon/react';
import SongFeed from './SongFeed';


function TrackPage() {
  const [track, setTrack] = useState(null);
  const { trackId } = useParams(); // Get the artistId from the URL
  useEffect(() => {
    // Function to fetch artist data from the backend
    const fetchTrackDetails = async () => {
      const response = await fetch(`/Apis/track_detail/${trackId}`, {credentials: 'include'});

      const data = await response.json();
      setTrack(data);
    };

    fetchTrackDetails();

  }, [trackId]);

  if (!track) {
    return <div>Loading...</div>;
  }

  const handleSubscribe = async (e) => {
  e.preventDefault();
  console.log('Go to subscribe page');
  };

  return (
    <div>
      <div className="trackHeader">
        <h2 className ="trackHeader">{track.name}</h2>
      </div>
    </div>
  );
}

export default TrackPage;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from './Layout';
import artistImage from './images/dannybrown.png'
import { CheckmarkFilled } from '@carbon/icons-react';
import { useCookies } from 'react-cookie';

import {
  Form,
  FormGroup,
  TextInput,
  RadioButton,
  Button,
  Checkbox,
  RadioButtonGroup,
  Stack,
  Select,
  FormLabel,
  SelectItem,
  DatePicker,
  DatePickerInput,
  MultiSelect,
  NumberInput,
  Breadcrumb,
  BreadcrumbItem,
  FileUploaderDropContainer,
  FileUploaderItem,
  Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction, Tabs, TabList, Tab, TabPanels, TabPanel, TextArea, InlineNotification
} from '@carbon/react';

import SongFeed from './SongFeed';
import './ArtistEditPage.css'


function ArtistEditPage() {
  const [artist, setArtist] = useState();
  const [artistName, setArtistName] = useState('');
  const [city, setCity] = useState('');
  const [bio, setBio] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitMessageType, setSubmitMessageType] = useState('');
  const [cookies] = useCookies(['csrftoken']);
  const { artistId } = useParams(); // Get the artistId from the URL
  const [profileImage, setProfileImage] = useState('');
  const [coverImage, setCoverImage] = useState('');


  useEffect(() => {
    // Function to fetch artist data from the backend
    const fetchArtist = async () => {
      const response = await fetch(`/Apis/artists/${artistId}`);

      const data = await response.json();
      setArtist(data);
      setArtistName(data.name);
      setCity(data.city);
      setBio(data.bio);
    };

    fetchArtist();
  }, [artistId]);


  const handleProfileImageUpload = (event) => {
    const imageFile = event.target.files[0];
    console.log(imageFile);
    console.log('uploaded');
    setProfileImage(imageFile);
  };

  const handleCoverImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setCoverImage(imageFile);
  };


  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var form = new FormData();
      //form.append('file', state.files[0]);
      console.log(city);
      console.log(bio);
      form.append('id', artistId);
      form.append('artistName', artistName);
      form.append('city', city);
      form.append('bio', bio);

      form.append('prof_image', profileImage)
      form.append('cover_image', coverImage)

    // Make the HTTP request using fetch or
      const response = await fetch('/Apis/edit_artist', {
          method: 'POST',
           headers: {
            'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
          },
          body: form,
      });
    // Handle the response (e.g., show success message, redirect, etc.)
    if (response.ok) {
        console.log('Created Artist Successfully');
        setSubmitMessage('Saved Artist!');
        setSubmitMessageType("success");
    } else {
        console.log(response);
    }
    } catch (error) {
        console.error('Error during save:', error);
    }
};



  return (
    <div className="ArtistFormContainer">
      <Form className="ArtistForm" onSubmit={handleSubmit}>
        <div class="name">
        <TextInput
          id="artist-name"
          labelText="Artist Name"
          placeholder={artistName}
          value={artistName}
          size="lg"
          onChange={(e) => setArtistName(e.target.value)}
        />
        <a class="name-info-text"> Use your name’s spelling without any special characters. </a>
        </div>
        <div class="location">
        <TextInput
          id="location"
          labelText="Base of Operations"
          placeholder={city}
          value={city}
          size="lg"
          onChange={(e) => setCity(e.target.value)}
        />
        <a class="info-text"> This is how your name will display on your artist page, it should roughly match your Artist Name but may include special characters. </a>
        </div>
        <TextArea
          id="textarea-bio"
          labelText="Artist Bio"
          placeholder={bio}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          enableCounter = {true}
          maxCount = {1000}
          rows={15}
        />

        <div>
          <b> Profile Image</b>
          <FileUploaderDropContainer
            style={{ color: '#8F7EFB' }}
            innerRef={{
              current: '[Circular]'
            }}
            labelText="Drag and drop files here or click to upload"
            multiple
            name=""
            onAddFiles={handleProfileImageUpload}
            tabIndex={0}
          />
          {profileImage && <FileUploaderItem
            name={profileImage.name}
            labelText={profileImage.name}
            status={'complete'}
          />}
        </div>

        <div>
          <b> Cover Image</b>
          <FileUploaderDropContainer
            style={{ color: '#8F7EFB' }}
            innerRef={{
              current: '[Circular]'
            }}
            labelText="Drag and drop files here or click to upload"
            multiple
            name=""
            onAddFiles={handleCoverImageUpload}
            tabIndex={0}
          />
          {coverImage && <FileUploaderItem
            name={coverImage.name}
            labelText={coverImage.name}
            status={'complete'}
          />}

        </div>

        <Button type="submit" className="bx--btn bx--btn--primary">
          Save
        </Button>
      </Form>


      {submitMessageType && (submitMessageType === 'success' ?  <InlineNotification
      aria-label="success"
      className="successMessage"
      size="sm"
      kind="success"
      onClose={function noRefCheck(){}}
      onCloseButtonClick={function noRefCheck(){}}
      statusIconDescription="notification"
      subtitle="Saved Artist!"
      title="Saved Artist"
    /> : <InlineNotification
      aria-label="error"
      size="sm"
      kind="error"
      onClose={function noRefCheck(){}}
      onCloseButtonClick={function noRefCheck(){}}
      statusIconDescription="notification"
      subtitle="Error saving artist"
      title="Error saving artist"
      className="small-inline-notification"
    /> )}

    </div>
  );
}

export default ArtistEditPage;